import {
  AUDIT_STATUS,
  UNRESPONSIVE_TIME_LIMIT,
  resolveAuditStatus,
  UNRESPONSIVE_STATUS_ROLE_WHITELIST,
} from "../../const/audit";

export function applyStatusFilter(rawProjects, filterGroup, logger) {
  if (!filterGroup?.statusList) {
    return rawProjects;
  }
  return rawProjects.filter((project) => {
    try {
      return (filterGroup.statusList || []).includes(resolveAuditStatus(project.status));
    } catch (err) {
      console.error(err.message);
      logger && logger.log("resolveAuditStatus_error", err.message);
      return false;
    }
  });
}

const orderByValue = (project, filterGroup, fieldMap) => {
  const orderByKeyNames = fieldMap[filterGroup?.orderBy]?.fields || ["updatedAt"];
  return orderByKeyNames.reduce((accu, cur) => (project[cur] > accu ? project[cur] : accu), 0); // max among fields
};

export function applyTimeRange(rawProjects, filterGroup, fieldMap) {
  const now = new Date().getTime();
  const filteredByTimeProjects =
    !filterGroup?.timeRange || filterGroup.timeRange === -1
      ? rawProjects
      : rawProjects.filter(
          (project) =>
            orderByValue(project, filterGroup, fieldMap) &&
            now - orderByValue(project, filterGroup, fieldMap) <=
              filterGroup.timeRange * 24 * 60 * 60 * 1000
        );

  return filteredByTimeProjects;
}

export function applyTimeRangeAndOrderBy(rawProjects, filterGroup, fieldMap) {
  const filteredByTimeProjects = applyTimeRange(rawProjects, filterGroup, fieldMap);

  const sortedProjects = filteredByTimeProjects.sort((a, b) => {
    if (orderByValue(a, filterGroup, fieldMap) && orderByValue(b, filterGroup, fieldMap)) {
      return orderByValue(b, filterGroup, fieldMap) - orderByValue(a, filterGroup, fieldMap);
    } else if (orderByValue(a, filterGroup, fieldMap)) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortedProjects;
}

export function filterUnresponsiveProjects(projects) {
  const now = Date.now();
  return projects.filter((projectAssembly) => {
    // Unresponsive definition:
    // 1. Project is not updated within 21 days
    // 2. For both finding and project comment: if there is any comment,
    // and the latest comment is from CertiK and got no reply from client for 21 days
    // 3. Project without update for 21 days and no finding/project comment at all is also considered as unresponsive
    try {
      return (
        (projectAssembly["lastFindingCommentByCertikAt"] || 0) >=
          (projectAssembly["lastFindingCommentByClientAt"] || 0) &&
        now - (projectAssembly["lastFindingCommentByCertikAt"] || 0) > UNRESPONSIVE_TIME_LIMIT &&
        (projectAssembly["lastProjectMessageByCertikAt"] || 0) >=
          (projectAssembly["lastProjectMessageByClientAt"] || 0) &&
        now - (projectAssembly["lastProjectMessageByCertikAt"] || 0) > UNRESPONSIVE_TIME_LIMIT &&
        resolveAuditStatus(projectAssembly["status"]) !== AUDIT_STATUS.COMPLETED &&
        now - projectAssembly.updatedAt > UNRESPONSIVE_TIME_LIMIT
      );
    } catch (err) {
      console.error(err.message);
      return false;
    }
  });
}

export function createUnresponsiveProjectIdMap(unresponsiveProjects) {
  const unresponsiveProjectIdMap = {};
  unresponsiveProjects.forEach((project) => {
    unresponsiveProjectIdMap[project?.id] = true;
  });
  return unresponsiveProjectIdMap;
}

/**
 * Adds/removes unresponsive projects to a provided project list. Only specific roles have the ability to filter for
 * unresponsive projects, so unwhitelisted roles just return the original list.
 * The unresponsive filter is not tied to a status (e.g. "completed) - any project can be unresponsive as well as have a status, since
 * unresponsiveness is determined by a lack of activity.
 *
 * Behavior:
 * - Not whitelisted: return original projectsToFilter
 * - Wants unresponsive projects:
 *     Remove unresponsive projects from projectsToFilter, concatenate ALL unresponsive projects to projectsToFilter (prevents duplicates since projectsToFilter can contain unresponsive projects).
 * - Doesn't want unresponsive projects:
 *     Just remove unresponsive projects from projectsToFilter
 *
 * @param {object[]} allProjects Entire project table - will filter it for unresponsive projects
 * @param {object[]} projectsToFilter Original list which you want to add/remove unresponsive projects to
 * @param {object} filterGroup {statusList: ["audit-started", "quote-submitted", "unresponsive", etc], ...otherfilters}
 * @param {string} role
 */
export function addOrRemoveUnresponsive(allProjects, projectsToFilter, filterGroup, role) {
  // If not a role that can filter for "unresponsive," don't do anything different
  if (!UNRESPONSIVE_STATUS_ROLE_WHITELIST.includes(role)) return projectsToFilter;
  // Role can filter for "unresponsive"
  const unresponsiveProjects = filterUnresponsiveProjects(allProjects);
  const unresponsiveProjectIdMap = createUnresponsiveProjectIdMap(unresponsiveProjects);
  const projectsWithoutUnresponsive = projectsToFilter.filter(
    (project) => !unresponsiveProjectIdMap[project?.id]
  );
  // Unresponsive projects wanted
  if (filterGroup?.statusList?.includes("unresponsive")) {
    return [...projectsWithoutUnresponsive, ...unresponsiveProjects];
  }
  // Unresponsive projects not wanted
  return projectsWithoutUnresponsive;
}

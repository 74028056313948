import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Collapse, Radio, Row } from "antd";
import React, { useMemo, useState } from "react";
import { AUDIT_STATUS, COLORS } from "../../../const";
import { Box } from "../Box";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text } from "../Typography";
import { DownArrow, FilterClearIcon, UpArrow } from "../Icons";
import { useBreakpoint, useTheme } from "../../../hooks";

export const DEFAULT_STATE_FOR_UNRESPONSIVE_PROJECTS = {
  projectStatus: {
    all: true,
    [AUDIT_STATUS.NEW]: true,
    [AUDIT_STATUS.QUOTE_SUBMITTED]: true,
    [AUDIT_STATUS.QUOTE_APPROVED]: true,
    [AUDIT_STATUS.AUDIT_STARTED]: true,
    [AUDIT_STATUS.PRE_REPORT_READY]: true,
    [AUDIT_STATUS.PRE_REPORT_SIGNED_OFF]: true,
    [AUDIT_STATUS.FINAL_REPORT_READY]: true,
    [AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF]: true,
    [AUDIT_STATUS.COMPLETED]: true,
    unresponsive: true,
  },
  timeRange: -1,
  orderBy: "lastReportComment",
};

export const DEFAULT_STATE = {
  projectStatus: {
    all: true,
    [AUDIT_STATUS.NEW]: true,
    [AUDIT_STATUS.QUOTE_SUBMITTED]: true,
    [AUDIT_STATUS.QUOTE_APPROVED]: true,
    [AUDIT_STATUS.AUDIT_STARTED]: true,
    [AUDIT_STATUS.PRE_REPORT_READY]: true,
    [AUDIT_STATUS.PRE_REPORT_SIGNED_OFF]: true,
    [AUDIT_STATUS.FINAL_REPORT_READY]: true,
    [AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF]: true,
    [AUDIT_STATUS.COMPLETED]: true,
  },
  timeRange: -1,
  orderBy: "lastReportComment",
};

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const StyledCollapse = styled(Collapse)`
  & {
    background: #f5f5f5;
  }
  & .ant-collapse-item {
    border-bottom: 0px;
  }
  & .ant-collapse-item .ant-collapse-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & .ant-collapse-content-box {
    padding-left: 0;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 4px;
  padding: 6px 16px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  height: fit-content;
  ${(props) => !props.disabled && `color: ${COLORS["gray-50"]};`}
`;

export const CheckboxFilter = ({ label, checked, onChange }) => {
  return (
    <StyledCol xl={24}>
      <Checkbox label={label} checked={checked} onChange={onChange}>
        {label}
      </Checkbox>
    </StyledCol>
  );
};

export const CheckboxGroup = ({ options, onChange }) => (
  <Row>
    <Col xl={24}>
      <div className="ant-checkbox-group" style={{ display: "inline-block", marginRight: 0 }}>
        {options.map((option) => (
          <Row gutter={[0, 8]} justify={"start"} key={option.key}>
            <CheckboxFilter
              key={option.key}
              label={option.label}
              onChange={(e) => onChange(option.value, e.target.checked)}
              checked={option.checked}
            />
          </Row>
        ))}
      </div>
    </Col>
  </Row>
);

export const RadioBoxGroup = ({ options, value, onChange }) => {
  return (
    <Row>
      <Col xl={24}>
        <div className="ant-checkbox-group" style={{ display: "inline-block", marginRight: 0 }}>
          <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
            {options.map((option) => (
              <Row gutter={[0, 8]} justify={"start"} key={option.label}>
                <Col span={24}>
                  <Radio key={option.label} label={option.label} value={option.value}>
                    {option.label}
                  </Radio>
                </Col>
              </Row>
            ))}
          </Radio.Group>
        </div>
      </Col>
    </Row>
  );
};

// Segmented control is available in antd since v4.20.0. We have antd v4.16.0.
// So to avoid upgrading antd, a segmented control component is made.
export function SegmentedControl({ options = [], value, onChange, height = 32 }) {
  return (
    <Row style={{ height, maxWidth: 250 }}>
      {options.map((option, index) => (
        <Col
          span={Math.floor(24 / options?.length)}
          onClick={() => onChange(option.value)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",

            // left most box styling
            ...(index === 0 ? { borderTopLeftRadius: 4, borderBottomLeftRadius: 4 } : {}),

            // right most box styling
            ...(index === options.length - 1
              ? { borderTopRightRadius: 4, borderBottomRightRadius: 4 }
              : {}),

            // selected box styling
            ...(value === option.value
              ? { background: COLORS["CK2"], color: "white", border: `1px solid ${COLORS["CK2"]}` }
              : { border: `1px solid ${COLORS["gray-400"]}` }),
          }}
          key={option.label}
        >
          {option.label}
        </Col>
      ))}
    </Row>
  );
}

export function AdvancedSearchBar({
  onCollapse,
  collapse = false,
  setSearchQuery = () => {},
  children = () => <></>,
  onClear = () => {},
  onApply = () => {},
  equalToDefaultFilter = null, // last applied filter state equal to default? (from parent)
  equalToLastFilter = null, // UI component state equal to last applied filter state? (from parent)
}) {
  const { t } = useTranslation();
  const [filterCollapse, setFilterCollapse] = useState(collapse);
  const [filterApplied, setFilterApplied] = useState(false);

  const compoundFilterApplied =
    equalToDefaultFilter != null ? !equalToDefaultFilter || filterApplied : filterApplied; // decides color, "Clear" btn. Compatible with state from parent and local state here
  const filterLastChanged = equalToLastFilter != null ? !equalToLastFilter : false; // decides "Apply" btn

  const computedProperty = {
    bgColor: compoundFilterApplied ? COLORS["red-100"] : COLORS["gray-3"],
    barText: compoundFilterApplied
      ? `${t("advancedSearchGroup.filter", "Filter")} (${t(
          "advancedSearchGroup.applied",
          "Applied"
        )})`
      : t("advancedSearchGroup.filter", "Filter"),
  };
  const clickTopBar = () => {
    onCollapse(!filterCollapse);
    setFilterCollapse(!filterCollapse);
  };

  const mediaQuery = window.matchMedia("(max-width: 78rem)");
  mediaQuery.addEventListener("change", () => {
    if (filterCollapse && mediaQuery.matches) {
      onCollapse(true);
      setFilterCollapse(true);
    }
  });

  let clearFiltersOption = () => {};
  const getOptionCleaner = (clearFiltersFn) => {
    clearFiltersOption = clearFiltersFn;
  };

  const clickClear = () => {
    setFilterApplied(false);
    onClear(); // from parent
    clearFiltersOption(); // from child
    setSearchQuery("");
  };

  const clickApply = () => {
    onApply();
  };

  return (
    <>
      <Box
        hidden={filterCollapse}
        className="table-filter-group-bar"
        style={{ backgroundColor: COLORS["gray-3"], height: "100%" }}
      >
        <Box
          pt={10}
          pb={10}
          pl={20}
          pr={15}
          style={{ borderBottom: `1px solid ${COLORS["gray-5"]}` }}
        >
          <Row justify="space-between" style={{ height: 32 }}>
            <StyledCol>{t("advancedSearchGroup.filter", "Filter")}</StyledCol>
            <StyledCol>
              <Row gutter={8}>
                {compoundFilterApplied && (
                  <StyledCol onClick={clickClear}>
                    <a style={{ color: COLORS["certikRed"] }}>
                      {t("advancedSearchGroup.clear", "Clear")}
                    </a>
                  </StyledCol>
                )}
                {filterLastChanged && (
                  <StyledCol>
                    <Button
                      type="primary"
                      onClick={clickApply}
                      data-testid="advanced-search-apply-button"
                    >
                      {t("advancedSearchGroup.apply", "Apply")}
                    </Button>
                  </StyledCol>
                )}
                <StyledCol>
                  <LeftOutlined onClick={clickTopBar} />
                </StyledCol>
              </Row>
            </StyledCol>
          </Row>
        </Box>
        <Box pt={20} pl={20} pr={20}>
          {children({ setFilterApplied, getOptionCleaner })}
        </Box>
        <Row gutter={8} justify="end" style={{ marginTop: 24, padding: "0 36px" }}>
          {compoundFilterApplied && (
            <StyledCol onClick={clickClear}>
              <a style={{ color: COLORS["certikRed"] }}>
                {t("advancedSearchGroup.clear", "Clear")}
              </a>
            </StyledCol>
          )}
          {filterLastChanged && (
            <StyledCol>
              <Button type="primary" onClick={clickApply}>
                {t("advancedSearchGroup.apply", "Apply")}
              </Button>
            </StyledCol>
          )}
        </Row>
      </Box>
      <Box
        hidden={!filterCollapse}
        mr={24}
        pt={10}
        onClick={clickTopBar}
        style={{
          backgroundColor: computedProperty.bgColor,
          height: "100%",
          width: "40px",
          cursor: "pointer",
        }}
      >
        <Row justify="start" gutter={[0, 16]}>
          <Col offset={8}>
            <RightOutlined data-testid="expand-advanced-search-button" />
          </Col>
        </Row>

        <Box mt={18} ml={10}>
          <Row justify="center" style={{ writingMode: "vertical-rl", fontSize: 16 }}>
            <Col flex="auto">{computedProperty.barText}</Col>
          </Row>
        </Box>
      </Box>
    </>
  );
}

export function AdvancedSearchBarHorizontal({
  onCollapse,
  collapse = false,
  setSearchQuery = () => {},
  children = () => <></>,
  onClear = () => {},
  onApply = () => {},
  equalToDefaultFilter = null, // last applied filter state equal to default? (from parent)
  equalToLastFilter = null, // UI component state equal to last applied filter state? (from parent)
  splitViewEnabled,
  disabled = false,
}) {
  const { t } = useTranslation();
  const [theme] = useTheme();
  const { lg, xxl } = useBreakpoint();
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterText, setFilterText] = useState("");

  const isSmallScreen = useMemo(
    () => (theme?.layout?.split && splitViewEnabled ? !xxl : !lg),
    [theme, lg, xxl]
  );

  const compoundFilterApplied =
    equalToDefaultFilter != null ? !equalToDefaultFilter || filterApplied : filterApplied; // decides color, "Clear" btn. Compatible with state from parent and local state here
  const filterLastChanged = equalToLastFilter != null ? !equalToLastFilter : false; // decides "Apply" btn

  const clickTopBar = () => {
    onCollapse(!collapse);
  };

  let clearFiltersOption = () => {};
  const getOptionCleaner = (clearFiltersFn) => {
    clearFiltersOption = clearFiltersFn;
  };

  const clickClear = () => {
    setFilterApplied(false);
    onClear(); // from parent
    clearFiltersOption(); // from child
    setSearchQuery("");
  };

  const clickClearAndClose = () => {
    clickClear();
    clickTopBar();
  };

  const clickApply = () => {
    onApply();
    onCollapse(!collapse);
  };

  return !collapse || compoundFilterApplied ? (
    <>
      <Box
        hidden={collapse}
        className="table-filter-group-bar"
        style={{
          width: "100%",
          padding: "16px 40px 24px",
          backgroundColor: COLORS["gray-100"],
          height: "fit-content",
          boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box>
          <Row justify="space-between" style={{ height: 32, gap: 21 }} wrap={false}>
            <StyledCol flex="auto">
              <Text level={3} weight={600} color={COLORS["gray-800"]}>
                {t("advancedSearchGroup.filter", "Filter")}
              </Text>
            </StyledCol>
            {compoundFilterApplied && (
              <StyledCol flex="60px" style={{ cursor: "pointer" }} onClick={clickClear}>
                <FilterClearIcon style={{ marginRight: 7 }} />
                <Text level={3} weight={500} color={COLORS["gray-800"]}>
                  {t("advancedSearchGroup.clear", "Clear")}
                </Text>
              </StyledCol>
            )}
            <StyledCol flex="10px" style={{ cursor: "pointer" }} onClick={clickTopBar}>
              <UpArrow />
            </StyledCol>
          </Row>
        </Box>
        <Box pt={16}>
          {children({ setFilterApplied, getOptionCleaner, setFilterText, splitViewEnabled })}
        </Box>
        {(compoundFilterApplied || filterLastChanged) &&
          (isSmallScreen ? (
            <Row justify="end" style={{ marginTop: 16 }} gutter={[8, 8]}>
              {filterLastChanged && (
                <StyledCol>
                  <StyledButton
                    type="primary"
                    onClick={clickApply}
                    style={{
                      color: COLORS["gray-50"],
                    }}
                  >
                    {t("advancedSearchGroup.apply", "Filter Now")}
                  </StyledButton>
                </StyledCol>
              )}
              {compoundFilterApplied && (
                <StyledCol>
                  <StyledButton
                    onClick={clickClear}
                    style={{
                      border: "1px solid #BDBDBD",
                      color: COLORS["gray-800"],
                    }}
                  >
                    {t("advancedSearchGroup.clear", "Clear")}
                  </StyledButton>
                </StyledCol>
              )}
              {compoundFilterApplied && (
                <StyledCol>
                  <StyledButton
                    onClick={clickClearAndClose}
                    style={{
                      border: "1px solid #BDBDBD",
                      color: COLORS["gray-800"],
                    }}
                  >
                    {t("advancedSearchGroup.clearAndClose", "Clear & Close")}
                  </StyledButton>
                </StyledCol>
              )}
            </Row>
          ) : (
            <Row
              justify={
                compoundFilterApplied && filterLastChanged
                  ? "space-between"
                  : filterLastChanged
                  ? "end"
                  : "start"
              }
              style={{ marginTop: 16 }}
            >
              {compoundFilterApplied && (
                <StyledCol>
                  <StyledButton
                    onClick={clickClear}
                    style={{
                      border: "1px solid #BDBDBD",
                      color: COLORS["gray-800"],
                    }}
                  >
                    {t("advancedSearchGroup.clear", "Clear")}
                  </StyledButton>
                </StyledCol>
              )}
              {filterLastChanged && (
                <StyledCol>
                  <StyledButton
                    type="primary"
                    onClick={clickApply}
                    disabled={disabled}
                    loading={disabled}
                  >
                    {disabled
                      ? t("skynet.skynetDrawer.contracts.loading") // "Loading..."
                      : t("advancedSearchGroup.apply", "Filter Now")}
                  </StyledButton>
                </StyledCol>
              )}
            </Row>
          ))}
      </Box>
      <Box
        hidden={!collapse}
        mr={24}
        pt={10}
        style={{
          backgroundColor: COLORS["gray-200"],
          height: "64",
          width: "100%",
          padding: "22px 40px",
          boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row justify="space-around" wrap={false} style={{ gap: 21 }}>
          <StyledCol flex="auto">
            <Text level={3} weight={600} color={COLORS["gray-800"]}>
              {compoundFilterApplied ? filterText : t("advancedSearchGroup.filter", "Filter")}
            </Text>
          </StyledCol>
          {compoundFilterApplied && (
            <StyledCol flex="60px" style={{ cursor: "pointer" }} onClick={clickClear}>
              <FilterClearIcon style={{ marginRight: 7 }} />
              <Text level={3} weight={500} color={COLORS["gray-800"]}>
                {t("advancedSearchGroup.clear", "Clear")}
              </Text>
            </StyledCol>
          )}
          <StyledCol flex="10px" style={{ cursor: "pointer" }} onClick={clickTopBar}>
            <DownArrow />
          </StyledCol>
        </Row>
      </Box>
    </>
  ) : null;
}
